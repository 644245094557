import { useEffect } from 'react';
import { navigate } from 'gatsby';
import Cookie from 'js-cookie';

const DevNotFoundPage = () => {
  useEffect(() => {
    const pathName = window.location.href;
    if (pathName.includes('/x/')) {
      Cookie.set('shortCode', pathName.slice(pathName.indexOf('/x/') + 3));
      navigate('/profile');
    }
    if (!pathName.includes('/x/')) {
      navigate('/404');
    }
  }, []);

  return null;
};

export default DevNotFoundPage;
